import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import SidePanelContentSection from '../../common2/side-panel/SidePanelContentSection';
import CustomerResponseMessage from './CustomerResponseMessage';

function DeclinedReasonSection(props) {
  const { customerResponse, i18n } = props;

  const translations = customerResponse?.translations || {};
  const translation = translations[i18n.language] || translations.en;
  const message = translation?.message || i18n.t('CLAIM_DECLINED_REASON_CHECK_EMAIL');

  return (
    <SidePanelContentSection heading="Declined reason">
      <CustomerResponseMessage html={message} />
    </SidePanelContentSection>
  );
}

DeclinedReasonSection.propTypes = {
  customerResponse: PropTypes.shape({
    translations: PropTypes.shape({
      en: PropTypes.shape({
        portalMessage: PropTypes.string,
        message: PropTypes.string,
      }),
      de: PropTypes.shape({
        portalMessage: PropTypes.string,
        message: PropTypes.string,
      }),
    }),
  }),
};

export default React.memo(withTranslation()(DeclinedReasonSection));
