import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Locale from '../../Translate/Locale';
import CustomerResponseMessage from './CustomerResponseMessage';

function getMessageFromCustomerResponse(customerResponse, language) {
  const { translations = {} } = customerResponse;
  const translation = translations[language] || translations.en;
  return translation?.message ?? null;
}

function getClaimComment(comment, i18n) {
  const actionRequiredComment = `ACTION_REQUIRED: ${comment}`;
  return i18n.exists(actionRequiredComment) ? actionRequiredComment : 'ACTION_REQUIRED: check email';
}

function ActionRequiredMessage(props) {
  const { customerResponse, comment, i18n } = props;
  const message = customerResponse && getMessageFromCustomerResponse(customerResponse, i18n.language);
  const claimComment = getClaimComment(comment, i18n);

  if (message) {
    return <CustomerResponseMessage html={message} />;
  }

  return <Locale>{claimComment}</Locale>;
}

ActionRequiredMessage.propTypes = {
  customerResponse: PropTypes.object,
  comment: PropTypes.string,
};

export default React.memo(withTranslation()(ActionRequiredMessage));
